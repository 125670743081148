export const links = [

  {
    name: "Services",
    sublinks: [
      { name: 'Sofa Making ', link: '/Sofa' },
      { name: 'Concept Design', link: '/Concept' },
      { name: 'Commercial & Office', link: '/Office' },
      { name: 'Modular Kitchen ', link: '/Kitchen' },
      { name: 'Wardrobe and Cupboards', link: '/Wardrobe' },
      { name: 'Ceiling & Tiling ', link: '/Ceiling' },
      { name: 'Fixture & Furniture', link: '/Fixture' },
      { name: 'Living Room ', link: '/Living' },
      { name: 'Bedroom ', link: '/Bedroom' },
      { name: '2D & 3D layout planning', link: '/Space' },
    ],
  },
  {
    name: "Styles",
    sublinks: [
      { name: 'Bohemian Style Design', link: '/Bohemian ' },
      { name: 'Transitional Style Design', link: '/Transitional ' },
      { name: 'Traditional Style Design', link: '/Traditional ' },
      { name: 'Scandinavian Style Design', link: '/Scandinavian ' },
      { name: 'Minimalist Style Design', link: '/Minimalist ' },
      { name: 'Rustic Style Design', link: '/Rustic ' },
      { name: 'Coastal Style Design', link: '/Coastal ' },
      { name: 'Midcentuary Style Design', link: '/MidCentuary ' },
      { name: 'Shabbychic Style Design', link: '/ShabbyChic ' },
      { name: 'Industrial Style Design', link: '/Industrial ' },
      { name: 'Hollywood Style Design', link: '/Hollywood ' },
      { name: 'Frenchcountry Style Design', link: '/FrenchCountry ' },
      { name: 'Modern Style Design', link: '/Modern ' },
      { name: 'Contemporary Style Design', link: '/Contemporary ' }
    ],
  },
];


